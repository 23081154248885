//Types
import {
  CLOSE_VOUCHER_TOAST,
  CLOSE_DIALOG,
  DELETE_VOUCHER,
  GET_VOUCHER,
  INSERT_VOUCHER,
  OPEN_VOUCHER_TOAST,
  OPEN_VOUCHER_DIALOG,
  UPDATE_VOUCHER,
} from "./voucher.type";

//Define InitialState
const initialState = {
  voucher: [],
  dialog: false,
  dialogData: null,
  toast: false,
  toastData: null,
  actionFor: null,
};

const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Category
    case GET_VOUCHER:
      return {
        ...state,
        voucher: action.payload,
      };

    //Insert category
    case INSERT_VOUCHER:
      const data = [...state.voucher];
      data.unshift(action.payload);
      return {
        ...state,
        voucher: data,
      };

    //Update category
    case UPDATE_VOUCHER:
      return {
        ...state,
        voucher: state.voucher.map((voucher) =>
          voucher._id === action.payload.id ? action.payload.data : voucher
        ),
      };

    //Delete category
    case DELETE_VOUCHER:
      return {
        ...state,
        voucher: state.voucher.filter((voucher) => voucher._id !== action.payload),
      };

    //Open Dialog
    case OPEN_VOUCHER_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    //Close Dialog
    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    //Open Toast
    case OPEN_VOUCHER_TOAST:
      return {
        ...state,
        toast: true,
        toastData: action.payload.data || null,
        actionFor: action.payload.for || null,
      };
    case CLOSE_VOUCHER_TOAST:
      return {
        ...state,
        toast: false,
        toastData: null,
        actionFor: null,
      };
    default:
      return state;
  }
};

export default voucherReducer;
