export const GET_CAMPAIGN = "GET_CAMPAIGN";

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";

export const OPEN_CAMPAIGN_DIALOG = "OPEN_CAMPAIGN_DIALOG";

export const CLOSE_CAMPAIGN_DIALOG = "CLOSE_CAMPAIGN_DIALOG";

export const OPEN_CAMPAIGN_TOAST = "OPEN_CAMPAIGN_TOAST";

export const CLOSE_CAMPAIGN_TOAST = "CLOSE_CAMPAIGN_TOAST";

export const GET_CAMPAIGN_STATISTICS = "GET_CAMPAIGN_STATISTICS";

export const GET_USER_STATISTICS = "GET_USER_STATISTICS";