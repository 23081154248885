import {
    GET_REFERRAL_TV_SERIES,
    GET_REFERRAL_TV_SERIES_DETAILS,
} from "./referralTvSeries.type";

import { modifyFiles } from "../../Util/ModifyFiles";

//Define initialState
const initialState = {
    referralSeries: [],
    referralSeriesDetails: [],
    dialog: false,
    dialogData: null,
    toast: false,
    toastData: null,
    actionFor: null,
    totalSeries: 0,
    totalSeriesDetails: 0,
};

const referralTvSeriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REFERRAL_TV_SERIES:
            return {
                ...state,
                referralSeries: action.payload.referralSeries,
                totalSeries: action.payload.totalSeries,
            };

        case GET_REFERRAL_TV_SERIES_DETAILS:
            return {
                ...state,
                referralSeriesDetails: action.payload.referralSeriesDetails,
                totalSeriesDetails: action.payload.totalSeriesDetails,
            };

        default:
            return state;
    }
};

export default referralTvSeriesReducer;
