//Types
import {
    CLOSE_CHECKIN_REWARD_TOAST,
    CLOSE_DIALOG,
    DELETE_CHECKIN_REWARD,
    GET_CHECKIN_REWARD,
    INSERT_CHECKIN_REWARD,
    OPEN_CHECKIN_REWARD_TOAST,
    OPEN_CHECKIN_REWARD_DIALOG,
    UPDATE_CHECKIN_REWARD,
} from "./checkinReward.type";

//Define InitialState
const initialState = {
    checkinReward: [],
    dialog: false,
    dialogData: null,
    toast: false,
    toastData: null,
    actionFor: null,
};

const checkinRewardReducer = (state = initialState, action) => {
    switch (action.type) {
        //Get Category
        case GET_CHECKIN_REWARD:
            return {
                ...state,
                checkinReward: action.payload,
            };

        //Insert category
        case INSERT_CHECKIN_REWARD:
            const data = [...state.checkinReward];
            data.unshift(action.payload);
            return {
                ...state,
                checkinReward: data,
            };

        //Update category
        case UPDATE_CHECKIN_REWARD:
            return {
                ...state,
                checkinReward: state.checkinReward.map((checkinReward) =>
                    checkinReward._id === action.payload.id ? action.payload.data : checkinReward
                ),
            };

        //Delete category
        case DELETE_CHECKIN_REWARD:
            return {
                ...state,
                checkinReward: state.checkinReward.filter((checkinReward) => checkinReward._id !== action.payload),
            };

        //Open Dialog
        case OPEN_CHECKIN_REWARD_DIALOG:
            return {
                ...state,
                dialog: true,
                dialogData: action.payload || null,
            };

        //Close Dialog
        case CLOSE_DIALOG:
            return {
                ...state,
                dialog: false,
                dialogData: null,
            };

        //Open Toast
        case OPEN_CHECKIN_REWARD_TOAST:
            return {
                ...state,
                toast: true,
                toastData: action.payload.data || null,
                actionFor: action.payload.for || null,
            };
        case CLOSE_CHECKIN_REWARD_TOAST:
            return {
                ...state,
                toast: false,
                toastData: null,
                actionFor: null,
            };
        default:
            return state;
    }
};

export default checkinRewardReducer;
