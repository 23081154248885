//Types
import {
    GET_USER,
    USER_DETAILS,
    GET_HISTORY,
    BLOCK_UNBLOCK_SWITCH,
    DELETE_USER,
    BULK_DELETE_USER,
    BULK_BLOCK_USER,
    BULK_UNBLOCK_USER,
    OPEN_BONUS_DIALOG,
    CLOSE_BONUS_DIALOG,
    UPDATE_BONUS_USER,
    GET_BONUS_HISTORY,
    GET_INTERACTION_HISTORY,
    EXPORT_USER,
    GET_USER_AFFILIATE,
    GET_DASHBOARD,
} from "./affiliateUser.type";

//Define initialState
const initialState = {
    user: [],
    history: [],
    totalHistoryUser: 0,
    dialogData: null,
    dialog: false,
    bonusHistory: [],
    interactionHistory: {},
    totalUser: 0,
    exportData: [],
    userAffiliate: [],
    userTotalAffiliate: 0,
    totalReferralUsers: 0,
    totalReferrals: 0,
    totalCommissions: 0,
    totalRecharge: 0,
};

const affiliateUserReducer = (state = initialState, action) => {
    switch (action.type) {
        //Get User
        case GET_USER:
            return {
                ...state,
                user: action.payload.user,
                totalUser: action.payload.total,
            };

        //Get User Details
        case USER_DETAILS:
            return {
                ...state,
                user: action.payload,
            };

        //Get user history
        case GET_HISTORY:
            return {
                ...state,
                history: action.payload.history,
                totalHistoryUser: action.payload.total,
            };

        case BLOCK_UNBLOCK_SWITCH:
            return {
                ...state,
                user: state?.user?.map((user) =>
                    user._id === action.payload.id ? action.payload.data : user
                ),
            };

        case DELETE_USER:
            return {
                ...state,
                user: state?.user?.map((user) =>
                    user._id === action.payload.id ? action.payload.data : user
                ),
            };

        case BULK_DELETE_USER:
            return {
                ...state,
                user: state?.user?.map((user) =>
                    action.payload.ids.includes(user._id) &&
                        user.status !== "DELETED_BY_ADMIN" &&
                        user.status !== "DELETED_BY_USER" ? { ...user, status: 'DELETED_BY_ADMIN' } : user
                ),
            };

        case BULK_BLOCK_USER:
            return {
                ...state,
                user: state?.user?.map((user) =>
                    action.payload.ids.includes(user._id) &&
                        user.status === "ACTIVE" ? { ...user, status: 'BLOCK_BY_ADMIN' } : user
                ),
            };

        case BULK_UNBLOCK_USER:
            return {
                ...state,
                user: state?.user?.map((user) =>
                    action.payload.ids.includes(user._id) &&
                        user.status === "BLOCK_BY_ADMIN" ? { ...user, status: 'ACTIVE' } : user
                ),
            };

        case OPEN_BONUS_DIALOG:
            return {
                ...state,
                dialog: true,
                dialogData: action.payload || null,
            };

        case CLOSE_BONUS_DIALOG:
            return {
                ...state,
                dialog: false,
                dialogData: null,
            };

        case UPDATE_BONUS_USER:
            return {
                ...state,
                user: state.user.map((user) =>
                    user._id === action.payload.id ? action.payload.data : user
                ),
            };

        //Get user history
        case GET_BONUS_HISTORY:
            return {
                ...state,
                bonusHistory: action.payload.data,
            };

        case GET_INTERACTION_HISTORY:
            return {
                ...state,
                interactionHistory: action.payload.data,
            };

        case EXPORT_USER:
            return {
                ...state,
                exportData: action.payload.user,
            };

        case GET_USER_AFFILIATE:
            return {
                ...state,
                userAffiliate: action.payload.affiliate,
                userTotalAffiliate: action.payload.total,
            };

        case GET_DASHBOARD:
            return {
                ...state,
                totalReferralUsers: action.payload.totalReferralUsers,
                totalReferrals: action.payload.totalReferrals,
                totalCommissions: action.payload.totalCommissions,
                totalRecharge: action.payload.totalRecharge,
            };

        default:
            return state;
    }
};

export default affiliateUserReducer;
