//Token and Key
import setToken from "../../Util/setToken";
import setDevKey from "../../Util/setDevKey";
import jwt_decode from "jwt-decode";
import { devKey, adminKey } from "../../Util/ServerPath";

//Types
import {
  SET_ADMIN,
  UNSET_ADMIN,
  SET_LOGIN_ERROR,
  CLEAR_LOGIN_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_NAME,
  OPEN_ADMIN_TOAST,
  CLOSE_ADMIN_TOAST,
  FORGOT_PASSWORD,
  GET_PROVIDER,
  CLOSE_DIALOG,
  OPEN_DIALOG,
  CREATE_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
} from "./admin.type";

//Define initialStates
const initialState = {
  isAuth: false,
  isAdmin: false,
  admin: {},
  toast: false,
  toastData: null,
  actionFor: null,
  password: {},
  provider: [],
  totalProvider: 0,
  dialog: false,
  dialogData: null,
  id: null,
};

const adminReducer = (state = initialState, action) => {
  let decoded;

  switch (action.type) {
    //Set admin
    case SET_ADMIN:
      if (action.payload) {
        decoded = jwt_decode(action.payload.token, adminKey);
        setToken(action.payload.token);
        setDevKey(devKey);

        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("refreshToken", action.payload.refreshToken);
        localStorage.setItem("key", devKey);
        localStorage.setItem("tokenExpires", decoded.exp);
      }

      return {
        ...state,
        isAuth: true,
        admin: decoded,
        flag: decoded,
        isAdmin: decoded.role === "ADMIN" ? true : false,
        id: decoded._id,
      };

    //unset admin
    case UNSET_ADMIN:
      localStorage.removeItem("token");
      localStorage.removeItem("key");
      localStorage.removeItem("tokenExpires");
      localStorage.removeItem("refreshToken");
      setDevKey(null);
      setToken(null);
      return {
        ...state,
        isAuth: false,
        admin: {},
      };

    //Update admin Profile
    case UPDATE_PROFILE:
      return {
        ...state,
        admin: {
          ...state,
          id: action.payload._id,
          name: action.payload.name,
          email: action.payload.email,
          image: action.payload.image,
          flag: action.payload.flag,
        },
        isAdmin: action.payload.role === "ADMIN" ? true : false,
        id: action.payload._id,
      };

    //Update Admin Name
    case UPDATE_PROFILE_NAME:
      return {
        ...state,
        admin: action.payload,
      };

    //Open admin Toast
    case OPEN_ADMIN_TOAST:
      return {
        ...state,
        toast: true,
        toastData: action.payload.data || null,
        actionFor: action.payload.for || null,
      };

    //Close admin Toast
    case CLOSE_ADMIN_TOAST:
      return {
        ...state,
        toast: false,
        toastData: null,
        actionFor: null,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };

    case GET_PROVIDER:
      return {
        ...state,
        provider: action.payload.provider,
        totalProvider: action.payload.pagination.totalItems,
      };

    //Open Dialog
    case OPEN_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    case CREATE_PROVIDER:
      const data = [...state.provider];
      data.unshift(action.payload);
      return {
        ...state,
        provider: data,
      }

    case UPDATE_PROVIDER:
      return {
        ...state,
        provider: state.provider.map((provider) => provider._id === action.payload._id ? action.payload : provider),
      };
      
    case DELETE_PROVIDER:
      return {
        ...state,
        provider: state.provider.map((provider) => provider._id === action.payload._id ? action.payload : provider),
      };

    default:
      return state;
  }
};

export default adminReducer;
