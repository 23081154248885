export const GET_SUBTITLE = "GET_SUBTITLE";

//Insert Region
export const INSERT_SUBTITLE = "INSERT_SUBTITLE";

//Update Region
export const UPDATE_REGION = "UPDATE_REGION";

//Delete Region
export const DELETE_SUBTITLE = "DELETE_SUBTITLE";

//Insert Open Dialog
export const OPEN_REGION_DIALOG = "OPEN_REGION_DIALOG";

//Close Dialog
export const CLOSE_DIALOG = "CLOSE_DIALOG";

//toast
export const OPEN_SUBTITLE_TOAST = "OPEN_SUBTITLE_TOAST";
export const CLOSE_SUBTITLE_TOAST = "CLOSE_SUBTITLE_TOAST";
