//Types
import {
  GET_USER,
  USER_DETAILS,
  GET_HISTORY,
  BLOCK_UNBLOCK_SWITCH,
  DELETE_USER,
  BULK_DELETE_USER,
  BULK_BLOCK_USER,
  BULK_UNBLOCK_USER,
  OPEN_BONUS_DIALOG,
  CLOSE_BONUS_DIALOG,
  UPDATE_BONUS_USER,
  GET_BONUS_HISTORY,
  GET_INTERACTION_HISTORY,
  EXPORT_USER,
} from "./user.type";

//Define initialState
const initialState = {
  user: [],
  history: [],
  totalHistoryUser: 0,
  dialogData: null,
  dialog: false,
  bonusHistory: [],
  interactionHistory: {},
  totalUser: 0,
  packages: [],
  userCountries: [],
  exportData: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get User
    case GET_USER:
      return {
        ...state,
        user: action.payload.user,
        totalUser: action.payload.total,
        packages: action.payload.packages,
        userCountries: action.payload.countries,
      };

    //Get User Details
    case USER_DETAILS:
      return {
        ...state,
        user: action.payload,
      };

    //Get user history
    case GET_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        totalHistoryUser: action.payload.total,
      };

    case BLOCK_UNBLOCK_SWITCH:
      return {
        ...state,
        user: state?.user?.map((user) =>
          user._id === action.payload.id ? action.payload.data : user
        ),
      };

    case DELETE_USER:
      return {
        ...state,
        user: state?.user?.map((user) =>
          user._id === action.payload.id ? action.payload.data : user
        ),
      };

    case BULK_DELETE_USER:
      return {
        ...state,
        user: state?.user?.map((user) =>
          action.payload.ids.includes(user._id) &&
            user.status !== "DELETED_BY_ADMIN" &&
            user.status !== "DELETED_BY_USER" ? { ...user, status: 'DELETED_BY_ADMIN' } : user
        ),
      };

    case BULK_BLOCK_USER:
      return {
        ...state,
        user: state?.user?.map((user) =>
          action.payload.ids.includes(user._id) &&
            user.status === "ACTIVE" ? { ...user, status: 'BLOCK_BY_ADMIN' } : user
        ),
      };

    case BULK_UNBLOCK_USER:
      return {
        ...state,
        user: state?.user?.map((user) =>
          action.payload.ids.includes(user._id) &&
            user.status === "BLOCK_BY_ADMIN" ? { ...user, status: 'ACTIVE' } : user
        ),
      };

    case OPEN_BONUS_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    case CLOSE_BONUS_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    case UPDATE_BONUS_USER:
      return {
        ...state,
        user: state.user.map((user) =>
          user._id === action.payload.id ? action.payload.data : user
        ),
      };

    //Get user history
    case GET_BONUS_HISTORY:
      return {
        ...state,
        bonusHistory: action.payload.data,
      };

    case GET_INTERACTION_HISTORY:
      return {
        ...state,
        interactionHistory: action.payload.data,
      };

    case EXPORT_USER:
      return {
        ...state,
        exportData: action.payload.user,
      };

    default:
      return state;
  }
};

export default userReducer;
