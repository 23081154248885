//Get QUEST
export const GET_QUEST = "GET_QUEST";

//Insert QUEST
export const INSERT_QUEST = "INSERT_QUEST";

//Update QUEST
export const UPDATE_QUEST = "UPDATE_QUEST";

//Delete QUEST
export const DELETE_QUEST = "DELETE_QUEST";

//Insert Open Dialog
export const OPEN_QUEST_DIALOG = "OPEN_QUEST_DIALOG";

//Close Dialog
export const CLOSE_DIALOG = "CLOSE_DIALOG";

//toast
export const OPEN_QUEST_TOAST = "OPEN_QUEST_TOAST";
export const CLOSE_QUEST_TOAST = "CLOSE_QUEST_TOAST";
