import {
  CLOSE_LOADER,
  OPEN_LOADER
} from "../store/Loader/loader.type";
import { baseURL, devKey } from "./ServerPath";
import store from "../store/Provider";

export const openSpinner = () => {
  return store.dispatch({ type: OPEN_LOADER });
};

export const closeSpinner = () => {
  return store.dispatch({ type: CLOSE_LOADER });
};

const getHeaders = () => ({
  "Content-Type": "application/json",
  key: `${devKey}`,
  // Authorization: `${localStorage.getItem("token")}`,
});

export const apiInstanceFetch = {
  baseURL: `${baseURL}`, // Set your default base URL here
  get: (url) => {
    openSpinner();
    return fetch(`${apiInstanceFetch?.baseURL}${url}`, {
      method: "GET",
      headers: getHeaders(),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  post: (url, data) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  patch: (url, data) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "PATCH",
      headers: getHeaders(),
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  put: (url, data) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  delete: (url) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "DELETE",
      headers: getHeaders(),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },
};

function handleErrors(response) {
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response?.status}`);
  }
  return response.json();
}
