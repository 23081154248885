import {
    GET_REFERRAL,
    GET_USER_REFERRAL,
} from "./referral.type";

const initialState = {
    referral: [],
    userReferral: [],
    dialog: false,
    dialogData: null,
    toast: false,
    toastData: null,
    actionFor: null,
    totalReferral: 0,
    userTotalReferral: 0,
};

const referralReducer = (state = initialState, action) => {
    switch (action.type) {
        //get history
        case GET_REFERRAL:
            return {
                ...state,
                referral: action.payload.referral,
                totalReferral: action.payload.total,
            };

        case GET_USER_REFERRAL:
            return {
                ...state,
                userReferral: action.payload.referral,
                userTotalReferral: action.payload.total,
            };

        default:
            return state;
    }
};

export default referralReducer;
