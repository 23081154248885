//Get VOUCHER
export const GET_VOUCHER = "GET_VOUCHER";

//Insert VOUCHER
export const INSERT_VOUCHER = "INSERT_VOUCHER";

//Update VOUCHER
export const UPDATE_VOUCHER = "UPDATE_VOUCHER";

//Delete VOUCHER
export const DELETE_VOUCHER = "DELETE_VOUCHER";

//Insert Open Dialog
export const OPEN_VOUCHER_DIALOG = "OPEN_VOUCHER_DIALOG";

//Close Dialog
export const CLOSE_DIALOG = "CLOSE_DIALOG";

//toast
export const OPEN_VOUCHER_TOAST = "OPEN_VOUCHER_TOAST";
export const CLOSE_VOUCHER_TOAST = "CLOSE_VOUCHER_TOAST";
