//Get User
export const GET_USER = "GET_USER";

//Get User Details
export const USER_DETAILS = "USER_DETAILS";

//Get history
export const GET_HISTORY = "GET_HISTORY";

//block Switch
export const BLOCK_UNBLOCK_SWITCH = "BLOCK_UNBLOCK_SWITCH";

export const DELETE_USER = "DELETE_USER";

export const BULK_DELETE_USER = "BULK_DELETE_USER";

export const BULK_BLOCK_USER = "BULK_BLOCK_USER";

export const BULK_UNBLOCK_USER = "BULK_UNBLOCK_USER";

export const OPEN_BONUS_DIALOG = "OPEN_BONUS_DIALOG";

export const CLOSE_BONUS_DIALOG = "CLOSE_BONUS_DIALOG";

export const GET_BONUS_HISTORY = "GET_BONUS_HISTORY";

export const UPDATE_BONUS_USER = "UPDATE_BONUS_USER";

export const GET_INTERACTION_HISTORY = "GET_INTERACTION_HISTORY";

export const EXPORT_USER = "EXPORT_USER";
