import {
    GET_EARNING,
    GET_TOP_REVENUE,
    APPROVE_TOP_REVENUE,
    REJECT_TOP_REVENUE,
} from "./earning.type";

const initialState = {
    earning: [],
    dialog: false,
    dialogData: null,
    toast: false,
    toastData: null,
    actionFor: null,
    totalEarning: 0,
    topRevenue: [],
    totalTopRevenue: 0,
};

const earningReducer = (state = initialState, action) => {
    switch (action.type) {
        //get history
        case GET_EARNING:
            return {
                ...state,
                earning: action.payload.earning,
                totalEarning: action.payload.total,
            };

        case GET_TOP_REVENUE:
            return {
                ...state,
                topRevenue: action.payload.topRevenue,
                totalTopRevenue: action.payload.total,
            };

        case APPROVE_TOP_REVENUE:
            return {
                ...state,
                topRevenue: state.topRevenue.map((revenue) =>
                    revenue._id === action.payload.id ? action.payload.data : revenue
                ),
            };

        case REJECT_TOP_REVENUE:
            return {
                ...state,
                topRevenue: state.topRevenue.map((revenue) =>
                    revenue._id === action.payload.id ? action.payload.data : revenue
                ),
            };

        default:
            return state;
    }
};

export default earningReducer;
