//Types
import {
    CLOSE_CAMPAIGN_TOAST,
    CLOSE_CAMPAIGN_DIALOG,
    DELETE_CAMPAIGN,
    GET_CAMPAIGN,
    OPEN_CAMPAIGN_TOAST,
    OPEN_CAMPAIGN_DIALOG,
    CREATE_CAMPAIGN,
    UPDATE_CAMPAIGN,
    OPEN_CAMPAIGN_UPDATE_DIALOG,
    GET_CAMPAIGN_STATISTICS,
    GET_USER_STATISTICS,
} from "./campaign.type";

//Define InitialState
const initialState = {
    campaign: [],
    totalCampaign: 0,
    dialog: false,
    dialogData: null,
    toast: false,
    toastData: null,
    actionFor: null,
    campaignStatistic: [],
    totalCampaignStatistic: 0,
    userStatistic: [],
    totalUserStatistic: 0,
};

const campaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CAMPAIGN:
            return {
                ...state,
                campaign: action.payload.campaigns,
                totalCampaign: action.payload.total,
            };

        case OPEN_CAMPAIGN_DIALOG:
            return {
                ...state,
                dialog: true,
                dialogData: action.payload || null,
            };

        case CREATE_CAMPAIGN:
            const data = [...state.campaign];
            data.unshift(action.payload);
            return {
                ...state,
                campaign: data,
            };

        case UPDATE_CAMPAIGN:
            return {
                ...state,
                campaign: state.campaign.map((campaign) =>
                    campaign._id === action.payload.id ? action.payload.data : campaign
                ),
            };

        //Delete category
        case DELETE_CAMPAIGN:
            return {
                ...state,
                campaign: state.campaign.filter((campaign) => campaign._id !== action.payload),
            };

        //Close Dialog
        case CLOSE_CAMPAIGN_DIALOG:
            return {
                ...state,
                dialog: false,
                dialogData: null,
            };

        //Open Toast
        case OPEN_CAMPAIGN_TOAST:
            return {
                ...state,
                toast: true,
                toastData: action.payload.data || null,
                actionFor: action.payload.for || null,
            };
        case CLOSE_CAMPAIGN_TOAST:
            return {
                ...state,
                toast: false,
                toastData: null,
                actionFor: null,
            };

        case GET_CAMPAIGN_STATISTICS:
            return {
                ...state,
                campaignStatistic: action.payload.affiliate,
                totalCampaignStatistic: action.payload.total,
            };

        case GET_USER_STATISTICS:
            return {
                ...state,
                userStatistic: action.payload.affiliate,
                totalUserStatistic: action.payload.total,
            };

        default:
            return state;
    }
};

export default campaignReducer;
