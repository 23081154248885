//Types
import {
  CLOSE_SUBTITLE_TOAST,
  CLOSE_DIALOG,
  DELETE_SUBTITLE,
  GET_SUBTITLE,
  INSERT_SUBTITLE,
  OPEN_SUBTITLE_TOAST,
  OPEN_REGION_DIALOG,
  UPDATE_REGION,
} from "./subtitle.type";

//Define InitialState
const initialState = {
  subtitle: [],
  totalSubtitle: 0,
  dialog: false,
  dialogData: null,
  toast: false,
  toastData: null,
  actionFor: null,
};

const subtitleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBTITLE:
      return {
        ...state,
        subtitle: action.payload.subtitle,
        totalSubtitle: action.payload.total,
      };

    //Insert category
    case INSERT_SUBTITLE:
      const data = [...state.subtitle];
      data.unshift(action.payload);
      return {
        ...state,
        subtitle: data,
      };

    //Update category
    case UPDATE_REGION:
      return {
        ...state,
        region: state.region.map((region) =>
          region._id === action.payload.id ? action.payload.data : region
        ),
      };

    //Delete category
    case DELETE_SUBTITLE:
      return {
        ...state,
        subtitle: state.subtitle.filter((subtitle) => subtitle._id !== action.payload),
      };

    //Open Dialog
    case OPEN_REGION_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    //Close Dialog
    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    //Open Toast
    case OPEN_SUBTITLE_TOAST:
      return {
        ...state,
        toast: true,
        toastData: action.payload.data || null,
        actionFor: action.payload.for || null,
      };
    case CLOSE_SUBTITLE_TOAST:
      return {
        ...state,
        toast: false,
        toastData: null,
        actionFor: null,
      };
    default:
      return state;
  }
};

export default subtitleReducer;
