import {
  GET_PAYMENTS,
} from "./paymentRequest.type";

const initialState = {
  payments: [],
  totalPayments: 0,
  dialog: false,
  dialogData: null,
  toast: false,
  toastData: null,
  actionFor: null,
};

const paymentRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    //get history
    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload.payments,
        totalPayments: action.payload.total,
      };

    default:
      return state;
  }
};

export default paymentRequestReducer;
