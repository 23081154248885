//Get Film Watchlist
export const GET_FILM_WATCHLIST = "GET_FILM_WATCHLIST";

//Insert Film Watchlist
export const INSERT_FILM_WATCHLIST = "INSERT_FILM_WATCHLIST";

//Delete Film Watchlist
export const DELETE_FILM_WATCHLIST = "DELETE_FILM_WATCHLIST";

//toast
export const OPEN_FILM_WATCHLIST_TOAST = "OPEN_FILM_WATCHLIST_TOAST";
export const CLOSE_FILM_WATCHLIST_TOAST = "CLOSE_FILM_WATCHLIST_TOAST";

//Dialog
export const OPEN_FILM_WATCHLIST_DIALOG = "OPEN_FILM_WATCHLIST_DIALOG";
export const CLOSE_FILM_WATCHLIST_DIALOG = "CLOSE_FILM_WATCHLIST_DIALOG";
