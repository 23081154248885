//get Dashboard
export const GET_DASHBOARD = "GET_DASHBOARD";

//get Analytic
export const GET_ANALYTIC = "GET_ANALYTIC";
export const GET_MOVIE_ANALYTIC = "GET_MOVIE_ANALYTIC";
export const GET_REVENUE_ANALYTIC = "GET_REVENUE_ANALYTIC";

//Dashboard Toast
export const OPEN_DASHBOARD_TOAST = "OPEN_DASHBOARD_TOAST";
export const CLOSE_DASHBOARD_TOAST = "CLOSE_DASHBOARD_TOAST";

//get countryWise user
export const GET_COUNTRY_WISE_USER = "GET_COUNTRY_WISE_USER";

// movie Web Serries

export const MOVIE_SERIES_ANACLITIC_DATA = "MOVIE_SERIES_ANACLITIC_DATA";

//get dashboard series
export const GET_DASHBOARD_SERIES = "GET_DASHBOARD_SERIES";

//get view analytic
export const GET_VIEW_ANALYTIC = "GET_VIEW_ANALYTIC";

//get user view analytic
export const GET_USER_VIEW_ANALYTIC = "GET_USER_VIEW_ANALYTIC";

//get paid user analytic
export const GET_PAID_USER_ANALYTIC = "GET_PAID_USER_ANALYTIC";

