import {
  GET_LUCKY_MONEY,
} from "./luckyMoney.type";

const initialState = {
  luckyMoney: [],
  dialog: false,
  dialogData: null,
  toast: false,
  toastData: null,
  actionFor: null,
  totalLuckyMoney: 0,
};

const luckyMoneyReducer = (state = initialState, action) => {
  switch (action.type) {
    //get history
    case GET_LUCKY_MONEY:
      return {
        ...state,
        luckyMoney: action.payload.luckyMoney,
        totalLuckyMoney: action.payload.total
      };

    default:
      return state;
  }
};

export default luckyMoneyReducer;
