//Types
import {
  GET_SUBMIT_TICKETS,
  APPROVE_TICKET,
  REJECT_TICKET,
} from "./userQuest.type";

//Define initialState
const initialState = {
  submitTickets: [],
  totalSubmitTickets: 0,
  dialogData: null,
  dialog: false,
};

const userQuestReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get User
    case GET_SUBMIT_TICKETS:
      return {
        ...state,
        submitTickets: action.payload.submitTickets,
        totalSubmitTickets: action.payload.total,
      };

    case APPROVE_TICKET:
      return {
        ...state,
        submitTickets: state.submitTickets.map((ticket) =>
          ticket._id === action.payload.id ? action.payload.data : ticket
        ),
      };

    case REJECT_TICKET:
      return {
        ...state,
        submitTickets: state.submitTickets.map((ticket) =>
          ticket._id === action.payload.id ? action.payload.data : ticket
        ),
      };

    default:
      return state;
  }
};

export default userQuestReducer;
