//Get CHECKIN_REWARD
export const GET_CHECKIN_REWARD = "GET_CHECKIN_REWARD";

//Insert CHECKIN_REWARD
export const INSERT_CHECKIN_REWARD = "INSERT_CHECKIN_REWARD";

//Update CHECKIN_REWARD
export const UPDATE_CHECKIN_REWARD = "UPDATE_CHECKIN_REWARD";

//Delete CHECKIN_REWARD
export const DELETE_CHECKIN_REWARD = "DELETE_CHECKIN_REWARD";

//Insert Open Dialog
export const OPEN_CHECKIN_REWARD_DIALOG = "OPEN_CHECKIN_REWARD_DIALOG";

//Close Dialog
export const CLOSE_DIALOG = "CLOSE_DIALOG";

//toast
export const OPEN_CHECKIN_REWARD_TOAST = "OPEN_CHECKIN_REWARD_TOAST";
export const CLOSE_CHECKIN_REWARD_TOAST = "CLOSE_CHECKIN_REWARD_TOAST";
