//Types
import {
  GET_WITHDRAW_REQUEST,
  APPROVE_WITHDRAW_REQUEST,
  REJECT_WITHDRAW_REQUEST,
} from "./withdrawRequest.type";

//Define initialState
const initialState = {
  withdraws: [],
  totalWithdraws: 0,
  dialogData: null,
  dialog: false,
};

const userQuestReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get User
    case GET_WITHDRAW_REQUEST:
      return {
        ...state,
        withdraws: action.payload.withdraws,
        totalWithdraws: action.payload.total,
      };

    case APPROVE_WITHDRAW_REQUEST:
      return {
        ...state,
        withdraws: state.withdraws.map((withdraw) =>
          withdraw._id === action.payload.id ? action.payload.data : withdraw
        ),
      };

    case REJECT_WITHDRAW_REQUEST:
      return {
        ...state,
        withdraws: state.withdraws.map((withdraw) =>
          withdraw._id === action.payload.id ? action.payload.data : withdraw
        ),
      };

    default:
      return state;
  }
};

export default userQuestReducer;
