//Types
import {
    CLOSE_QUEST_TOAST,
    CLOSE_DIALOG,
    DELETE_QUEST,
    GET_QUEST,
    INSERT_QUEST,
    OPEN_QUEST_TOAST,
    OPEN_QUEST_DIALOG,
    UPDATE_QUEST,
  } from "./quest.type";
  
  //Define InitialState
  const initialState = {
    quest: [],
    dialog: false,
    dialogData: null,
    toast: false,
    toastData: null,
    actionFor: null,
  };
  
  const questReducer = (state = initialState, action) => {
    switch (action.type) {
      //Get Category
      case GET_QUEST:
        return {
          ...state,
          quest: action.payload,
        };
  
      //Insert category
      case INSERT_QUEST:
        const data = [...state.quest];
        data.unshift(action.payload);
        return {
          ...state,
          quest: data,
        };
  
      //Update category
      case UPDATE_QUEST:
        return {
          ...state,
          quest: state.quest.map((quest) =>
            quest._id === action.payload.id ? action.payload.data : quest
          ),
        };
  
      //Delete category
      case DELETE_QUEST:
        return {
          ...state,
          quest: state.quest.filter((quest) => quest._id !== action.payload),
        };
  
      //Open Dialog
      case OPEN_QUEST_DIALOG:
        return {
          ...state,
          dialog: true,
          dialogData: action.payload || null,
        };
  
      //Close Dialog
      case CLOSE_DIALOG:
        return {
          ...state,
          dialog: false,
          dialogData: null,
        };
  
      //Open Toast
      case OPEN_QUEST_TOAST:
        return {
          ...state,
          toast: true,
          toastData: action.payload.data || null,
          actionFor: action.payload.for || null,
        };
      case CLOSE_QUEST_TOAST:
        return {
          ...state,
          toast: false,
          toastData: null,
          actionFor: null,
        };
      default:
        return state;
    }
  };
  
  export default questReducer;
  