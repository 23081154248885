//Types
import {
    CLOSE_FILM_WATCHLIST_TOAST,
    CLOSE_DIALOG,
    DELETE_FILM_WATCHLIST,
    GET_FILM_WATCHLIST,
    INSERT_FILM_WATCHLIST,
    OPEN_FILM_WATCHLIST_TOAST,
    OPEN_FILM_WATCHLIST_DIALOG,
    CLOSE_FILM_WATCHLIST_DIALOG,
} from "./filmWatchlist.type";
//Define InitialState
const initialState = {
    filmWatchlist: [],
    totalFilmWatchlist: 0,
    dialog: false,
    dialogData: null,
    toast: false,
    toastData: null,
    actionFor: null,
};

const filmWatchlistReducer = (state = initialState, action) => {
    switch (action.type) {
        //Get Film Watchlist
        case GET_FILM_WATCHLIST:
            return {
                ...state,
                filmWatchlist: action.payload.filmWatchlist,
                totalFilmWatchlist: action.payload.totalFilmWatchlist,
            };

        //Insert Film Watchlist
        case INSERT_FILM_WATCHLIST:
            const data = [...state.filmWatchlist];
            data.unshift(action.payload);
            return {
                ...state,
                filmWatchlist: data,
            };

        //Delete Film Watchlist
        case DELETE_FILM_WATCHLIST:
            return {
                ...state,
                filmWatchlist: state.filmWatchlist.filter((filmWatchlist) => filmWatchlist._id !== action.payload),
            };

        //Open Toast
        case OPEN_FILM_WATCHLIST_TOAST:
            return {
                ...state,
                toast: true,
                toastData: action.payload.data || null,
                actionFor: action.payload.for || null,
            };
        case CLOSE_FILM_WATCHLIST_TOAST:
            return {
                ...state,
                toast: false,
                toastData: null,
                actionFor: null,
            };

        //Open Dialog
        case OPEN_FILM_WATCHLIST_DIALOG:
            return {
                ...state,
                dialog: true,
                dialogData: action.payload || null,
            };

        //Close Dialog
        case CLOSE_FILM_WATCHLIST_DIALOG:
            return {
                ...state,
                dialog: false,
                dialogData: null,
            };

        default:
            return state;
    }
};

export default filmWatchlistReducer;
